html {
    height: 100%;
    
    body {
        height: 100%;

        #root {
            //background-color: red;
            height: 100%;

            .main_container {
                height: 100%;

                header {
                    height: 2%;
                    width: 100%;

                    background-color: rgb(250, 250, 250);

                    div {
                        height: 35%;
                        background-color: rgba(56,56,56,255);
                        position: relative;
                        top: 98%;
                    }
                }
                
                 #block_central {
                    height: 100%;

                    background-image: url("../assets/bureau003.jpg");
                    background-repeat: no-repeat;
                    background-size: 100%;

                    .block_content {
                        height: 90%;
                        width: 65%;
                        float: left;
                        position: relative;
        
                        left: 0px;
                        //background-color: rgb(255, 255, 255, 0.5);
                        //background-color: rgb(255, 255, 255, 0.1);
                        //background-color: yellow;
                        
                        .block_logoEntreprise {
                            display: inline-block;
                            //background-color: rgb(255, 255, 255, 1);
                                //background-color: red;
                            padding-top: 10px;
                            position: relative;
                            top: 110px;
                            left: 220px;
    
                            img {
                                width: 200px;
                            }
                        }
    
                        .block_text {
                            display: inline-block;
                            width: 90%;
                            position: relative;
                            top: 130px;
                            left: 40px;

                            padding-left: 100px;
    
                            border-radius: 10px;
                                
                            .block_titre_text {
                                font-size: 1.5em;
                                font-family: 'Helvetica', 'Arial','sans-serif';
                                color: white;
                            }

                            #text_monteroNettoyage {
                                margin: 0px; padding: 0px;
                                font-weight: 800;

                                //background-color: red;
                                span {
                                }

                                font-size: 3.4em;
                            }

                            #text_description {
                                margin: 0px; padding: 0px;
                                //background-color: yellow;
                                position: relative;
                                bottom: 20px;
                                left: 10px;
                            }
                        }
                    }

                    .form_content {
                        position: absolute;
                        right: 0px;
                        height: 95%;
                        max-height: 700px;
                        width: 23%;
                        
                        float: right;
                        margin-right: 150px;
                        background-color: rgb(255, 255, 255, 1);
                        border-radius: 0px 0px 20px 20px;
    
                        .block_phone {
                            height: 6%;
                            width: 65%;
                            border-radius: 20px;
                            margin: 20px 0px 0px 0px;
    
                            position: relative;
                            left: 220px;
    
                            background-color: rgba(0,171,159,255);
    
                            text-align: center;
    
                            .phone_number {
                                position: relative;
                                top: 2px;
                                right: 5px;
                                font-size: 1.5em;
                                color: white;
                            
    
                                .icon_phone {
                                    width: 20px;
                                    position: relative;
                                    bottom: 1px;
                                    left: 0px;
                                    margin-right: 5px;
                                    opacity: 0.5;
                                }
                            }
                        }
    
                        .block_textForm {
                            //background-color: red;
                            margin: 15px 10px 0px 20px;
    
                            text-align: center;
    
                            .textForm {
                                font-size: 1.1em;
                                font-family: 'Arial';
                                color: rgba(56,56,56, 1);
                            }
                        }
                    }
                }             

                #block_infos_services {
                    height: 80%;
                    text-align: center;
                    
                    //  background-color: rgba(0,171,159,255);
                    #text_servicesProposes {
                        display: inline-block;
                        margin: 120px 0px 50px 0px;

                        border-bottom: 1px solid rgb(68, 68, 68, 0.2);
                        font-size: 2.5em;
                        font-weight: 800;
                        font-family: 'Helvetica', 'Tahoma';
                        
                          
                    }

                    #block_div_icon {
                        background-color: blue;
                        height: 200%;
                        text-align: center;

                        .div_icon {
                            display: inline-block;
                            height: 350px;
                            width: 40%;
                            
                            margin: 50px 50px 0px 0px;
                            padding: 30px 0px 0px 0px;
                            background-color: red;
                            opacity: 0.7;
                            border: 1px solid rgb(72, 72, 72, 0.1);
                            border-radius: 10px;

                            .block_image {
                                height: 120px;
                                width: 120px;
                                margin: auto;

                                background-color: rgb(0,171,159,0.5);
                                border-radius : 80px;
                                border: 2px solid rgba(112,108,43,255);

                                img {
                                    width: 70px;
                                    position: relative;
                                    top: 22px;
                                    left: 1px;
                                    color: white;
                                }
                            }

                            .div_text {
                                position: relative;
                                top: 20px;
                                left: 10px;

                                p {
                                    position: relative;
                                    right: 10px;

                                    font-size: 0.8em;
                                    font-weight: 700;
                                }
                            }
                        }

                        #div_icon_bureaux {
                           // background-color: red;
                            //width: 20%;
                        }
                    }
                }

                footer {
                    height: 20%;
                    text-align: center;
                    background-color: rgb(241, 241, 241, 0.5);

                    #infos_footer {
                        display: inline-block;
                        position: relative;
                        top: 110px;
                        
                        p {
                            display: inline-block;
                            color: rgb(72, 72, 72, 0.5);
                            font-family: 'Helvetica', 'Tahoma';
                        }
                    }
                }
            }
        }

    }
}
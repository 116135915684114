.div_form {
    margin-top: 30px !important;

    #p-alert {
        width: 90%;
        position: absolute;
        top: 23%;
        left: 5%;
        font-size: 0.8em;
        color: green;
        text-align: center;
    }

    form {
        input, select {
            border-radius: 5px !important;
        }

        .inputControl {
            margin: 25px auto;
                    
            .form-control {
                font-style: italic;
                border-color: rgba(137,215,192,255);
            }

            .form-control::placeholder {
                color: rgba(56,56,56, 0.4);
            }
        }

        .form-select {
            width: 84%;
            margin: 25px auto;
            font-style: italic;
            border-color: rgba(137,215,192,255);
            color: rgba(56,56,56, 0.4);
        }

        .div-controlTextarea {
            .form-control-textarea {
                margin-left: 8%;
                //border-radius: 10px;
                width: 84%;
                font-style: italic;
                border-color: rgba(137,215,192,255);
                border-radius: 5px;
            }

            .form-control-textarea::placeholder {
                color: rgba(56,56,56, 0.4);
            }
        }

        .btn-signIn-style {
            width: 84%;
            color: white;
            background-color: #d56b01;
        }
        .div-submit {
            text-align: center;
            margin-top: 15px;

            button {
                
            }
        }
    }
}
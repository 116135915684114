header {
    //background-color: red;
    background-color: rgba(255, 255, 255, 0.0);

    
    .div_row {
        height: 100%;

        .my-header {
            //background-color: yellow;
            min-height: 100%;

            .row_my-header {
                min-height: 100%;

                .p_logo {
                    height: 100px;
                    width: 100px;   
                    
                    img {
                        //height: 110px;
                        width: 250px;
                        
                        position: relative;
                        top: 0px;
                    }
                }

                .p_phone {
                    float: right;
                    margin: 40px 25px 0px 0px;
                    //background-color: green;
                    opacity: 0.7;
                    color: #383838;

                    img {
                        position: relative;
                        bottom: 7px;
                        width: 30px;
                    }

                    .phone_number {
                        margin: 0px 0px 0px 6px; 
                        //background-color: red;
                        font-size: 2em;
                    }
                }
            }            
        }
    }
}
